<template>
	<el-dialog :title="$t('publish.import.import-result')" :visible.sync="visible" width="500">
		<div class="sub-title">
			<strong>{{$t('publish.import.not-accept')}}: </strong>
		</div>
		<el-table :data="importProblems" style="width: 100%;height:auto;max-height:500px;overflow-y: auto;"
			size="small">
			<el-table-column label="Cod produs" prop="products_model" width="260px">
			</el-table-column>
			<el-table-column prop="error_message" label="Motiv refuz">
			</el-table-column>
		</el-table>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="$emit('closeModal')">{{$t('general.close')}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		props: ['visible', 'importProblems'],
		data() {
			return {

			};
		},
		methods: {}
	};
</script>

<style>

</style>